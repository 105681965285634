 /*
 * App Style
 *
 * The base style for the application.
 *
 */
 @charset "utf-8/index";

 @import '_base.scss';
 @import '_typography.scss';
 @import '_variables.scss';
 @import '_mixins.scss';


@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

//selection-based styling
::selection {
  background: $green; /* WebKit/Blink Browsers */
}

html {
  --top-spacing: 100px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  transition: .4s cubic-bezier(.19,1,.22,1);
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background: $blue-pop;
  height: 60px;
  font: inherit;
  cursor: inherit;
  padding: 0 40px;
  border: none;
  color: white;
  transition: .3s ease-in-out;
  border-radius: 8px;
  &:hover {
    background: $green;
    transform: translateY(-1px) scale(1.05);
  }
  &:active {
    background: $green;
    transform: none;
  }
}

.colored-section {
  color: white;
}

.page-title {
  margin-top: 50px;
  text-align: left;
  font-weight: 700;
}

.page-content {
  max-width: 800px;
  margin: 0 auto;
}

.wiggle {
  width: 100vw;
  display: block;
  @include noSelect;
  position: relative;
  &.wiggle-top {
    margin-bottom: -5px;
    margin-top: calc(-2.976vw + 1px);
  }
  &.wiggle-bottom {
    margin-top: -5px;
    margin-bottom: calc(-2.976vw + 1px);
    z-index: 12;
  }
  &.reverse {
    transform: rotate(180deg);
  }
  &.light-blue-background {
    background: $blue-light;
  }
}

.wiggle-inner {
  width: 100vw;
  position: relative;
  z-index: 12;
  margin-bottom: -5px;
  @include noSelect;
  &.reverse{
    transform: rotate(180deg);
  }
  bottom: 0;
}

.loader-container {
  height: 100vh;
}

.section-hero {
  height: 200px;
  background-size: cover;
  background-color: $white;
  background-position: center;
  position: relative;
  width: 100%;
  img {
    position: absolute;
    top: 0;
    margin-bottom: -1px;
  }
  h1 {
    position: absolute;
    top: 100px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    color: $blue-dark;
  }
}

@media only screen and (max-width: 768px) {
  .page-hero {
    height: 400px;
  }
}

.page-hero {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  img {
    position: absolute;
    bottom: 0;
    margin-bottom: -1px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.25);
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $blue-dark;
    z-index: -1;
  }
  h1, .page-hero-title {
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    color: $white;
    z-index: 1;
    margin-top: 30px;
    max-width: 1000px;
  }
}

.section-title {
  text-transform: uppercase;
  text-align: center;
  padding-top: 70px;
}

.breakout {
  margin:1em -100%; /* old browsers fallback */
  margin:1em calc(50% - 50vw);
  .breakin {
    padding: 30px;
    max-width: 1200px;
  }
}

@media only screen and (max-width: 840px) {
  .page-content {
    margin: 0 20px;
  }
}
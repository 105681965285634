/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$bobby: "bobby", "times new roman", serif;
$source-sans: "Source Sans Pro", "system-ui",sans-serif;
$korolev: "korolev", sans-serif;
$montserrat: "montserrat", sans-serif;
$nexa: "nexa", sans-serif;
$rubik: 'Rubik', sans-serif;
$karla: 'Karla', 'sans-serif';

$bp-md: 1024px;
$bp-sm: 768px;

/* ============================================================ *\
|* Colors
\* ============================================================ */

$white:           #FFF;
$grey-light:      #E6E7E8;
$green:           #70FF4D;
$blue-dark:       #0A142D;
$blue:            #0E3475;
$blue-pop:        #1654F5;
$blue-light:      #9AADCE;
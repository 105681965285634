@import "style/_variables.scss";

@mixin smoothness {
  -webkit-transition: 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -moz-transition: 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -o-transition: 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -ms-transition: 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  transition: 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
}

@mixin noSelect {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

@mixin fillAvail {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

@mixin linkStyling {
  text-decoration: underline;
  &:hover {
    color: $green;
  }
}